import React from "react";
import { useTranslation } from 'react-i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { nl } from 'date-fns/locale';

const {add, sub}  = require('date-fns');

const buttonStyle = {  
    border: "none",
  margin: "0 10px", 
  width: "100px"
  
};

export default function DateFilter(props) {
    const { t } = useTranslation();

    const decrement = () => {
        const d = new Date(props.selectedDate);
        const nu = new Date();
        if (d >= nu){
            const newDate = sub(d, { days: 1 });
            props.onChange(newDate);
        }
    }

    const increment = () => {
        const d = new Date(props.selectedDate);
        const newDate = add(d, { days: 1 });
        props.onChange(newDate);
    }
    function disableWeekends(date) {
        return date.getDay() === 0 || date.getDay() === 6;
      }

  return (
    <div style={{ display: "flex", marginTop: "10px", justifyContent: "center" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={nl}>
        <button 
          disabled={props.isDisabled}
          style={buttonStyle}
          variant="contained"
          onClick={decrement}
        >
          &#60;
        </button>
        <DatePicker 
          disabled={props.isDisabled}
          shouldDisableDate={disableWeekends}
          disableToolbar
          disablePast
          showTodayButton={true}
          inputFormat="dd-MM-yyyy"
          margin="small"
          id="date-picker"
          label={t('wizard.step3text1')}
          mask="__-__-____"
          value={props.selectedDate}
          onChange={(value) => {props.onChange(value)}}
          renderInput={(props) => <TextField {...props}  />}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <button
          disabled={props.isDisabled}
          style={buttonStyle}
          variant="contained"
          onClick={increment}
        >
          &#62;
        </button>
      </LocalizationProvider>
    </div>
  );
}
