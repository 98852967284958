import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, LinearProgress } from "@mui/material";
import styles from "./Step5.module.css";
import { selectCarrier } from "../../../store/session";
import { useSelector } from "react-redux";

export default function Step5(props) {
    const { t } = useTranslation();
    const carrier = useSelector(selectCarrier);
    
    return (
    <div className={styles.container}>
      <div className={styles.summary}>
          {props.inProgress===true ? <LinearProgress />: ""}
          {props.bookingsucceeded===true?
        <p>{t('wizard.step5text1')}<br /><br/>
        {t('wizard.step5text2')} {carrier.email}.</p>
        :""
          }
      
      </div>
      <div></div>
      <div className={styles.buttons}>
        <Button disabled={props.inProgress} onClick={props.next}>{t('wizard.button5')}</Button>
      </div>
    </div>
  );
}
