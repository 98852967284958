import axios from 'axios';
import * as actions from "../api";

const apiURL = window.API_URL;

const api = ({dispatch}) => next =>async action =>{
    if (action.type !== actions.apiCallBegan.type) return next(action);
    
    const { url, method, data, onStart, onSuccess, onError } = action.payload;
    if (onStart) dispatch({ type: onStart });

    next(action);
    try {
        const response = await axios.request({
            withCredentials: true,
            baseURL:apiURL,
            url,
            method,
            data
        });
        dispatch(actions.apiCallSucceeded(response.data));
        if(onSuccess){
            dispatch({type: onSuccess, payload: response.data})
        }
    } catch (error) {
        const {response}  = error;
        if (response){
            const {data} = response;            
            console.log("Axios error: ", response);
            dispatch(actions.apiCallFailed({message: data}));
        }
        else
        {
            console.log("Error: ", error);
            dispatch(actions.apiCallFailed(error));
        }
        if (onError){
            if (response){
                const {data} = response;
                dispatch({type: onError, payload: data});
            }
            else
            {
                dispatch({type: onError, payload: {"message":"No response received from backend server."}});
            }

        }
    }
}

export default api;
