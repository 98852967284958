import * as React from "react";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styles from "./home.module.css";

export default function HomeContainer(props) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const lngs = {
        en: { nativeName: "English" },
        de: { nativeName: "Deutsch" },
        nl: { nativeName: "Nederlands" },
        fr: { nativeName: "Français" },
        pl: { nativeName: "Polski" },
        es: { nativeName: "Español" },
    };

    return (
        <div  className={styles.wrapper}>
            <header className={styles.header}>
                <div className={styles.buttons}>
                {Object.keys(lngs).map((lng) => (
                    <button
                        key={lng}
                        className={styles.button}
                        type="submit"
                        onClick={() => i18n.changeLanguage(lng)}
                    >
                        <img src={`./img/${lng}.png`} title={lngs[lng].nativeName} alt={lng}></img>
                    </button>
                ))}
            </div>
            </header>
            <main className={styles.main}>
                
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{height: "100%"}}
                >
                    <Grid item xs={1}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                                navigate("./timeslot");
                            }}
                        >
                            {t("home.button")}
                        </Button>
                    </Grid>
                </Grid>                
            </main>
            <footer className={styles.footer}></footer>
        </div>
    );
}
