import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import api from "./store/middleware/api";
import toast from "./store/middleware/toast";
import businesslogic from "./store/middleware/businesslogic";

//import errorhandler from "./store/middleware/errorhandler";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([toast, api, businesslogic])
});

export default store;
