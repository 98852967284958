import { toast } from "react-toastify";

const businesslogic = store => next => async action => {
    if(action.type === "shipments/shipmentsAdded") {
        const shipmentsToAdd = action.payload.shipments.data;
        const referenceNumber = action.payload.shipments.referenceNumber;
        const {shipments} = store.getState();

        for (let i=0;i<shipmentsToAdd.length;i++){
            const s = shipmentsToAdd[i];
            const {documentNumber, address } = s;
            const addressId = address.id || 0 ;
            // validate weather shipment already in list
            const exists1 = shipments.list.filter(a => a.documentNumber === documentNumber);
            const tot1 = [].concat(exists1);
            if (tot1.length > 0){
                toast.info(`Shipment ${referenceNumber} already in list.`);
                store.dispatch({type: "shipments/requestFailed", payload: {}});
                return;
            }
            // Controleer of de zending voor hetzelfde warehouse is 
            const exists2 = shipments.list.filter(a => a.address.id !== addressId);
            const tot2 = [].concat(exists2);
            if (tot2.length > 0){
                toast.info(`Shipment ${referenceNumber} is for other warehouse.`);
                store.dispatch({type: "shipments/requestFailed", payload: {}});
                return;
            }
        }
        return next(action);
    } 
    else
    return next(action);
  };
  
  export default businesslogic;
  