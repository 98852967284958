import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import {getTimeslotDate} from '../helpers/businessrules';

const initial = {
    list: [],
    loading: false,
}

const slice = createSlice({
  name: "shipments",
  initialState: initial,
  reducers: {
      shipmentRequested: (shipments, action) => {
        shipments.loading = true;
      },
      requestFailed: (shipments, action) => {
        shipments.loading = false;
      },
      clearShipments: (shipments, action) => {
        return initial;
      },
      shipmentsAdded: (shipments, action) => {
        const toAdd = action.payload.shipments.data.map(a =>  a);
        toAdd.forEach(s => s.referenceNumber=action.payload.shipments.referenceNumber);
      return {
        list: [
          ...shipments.list,
          ...toAdd,
        ],
      };
    },
    shipmentRemoved: (shipments, action) => {
        // const dd= documents.list.findIndex(i => i.UUID === action.uuid);
        // console.log(dd);
        const newState = [...shipments.list.filter(d => d.id !== action.payload.id)];
        //newState.forEach(element => console.log(element));
        return {
          list: [
            //...documents.list.filter((doc) => {return doc.uuid !== action.uuid})
            ...newState

          ],
        };
      }
    },
});

export const {
    shipmentRequested,
    requestFailed,
    shipmentsAdded,
    shipmentRemoved,
    clearShipments
} = slice.actions;

export default slice.reducer;

// Action Creators
const url = "/shipments";

export const AddShipment = (data) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        url: url + `/${data.shipmentno}?direction=${data.direction}`,
        method: "get",
        onStart: shipmentRequested.type,
        onSuccess: shipmentsAdded.type,
        onError: requestFailed.type
      })
    );
  };

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }


// Selector
export const selectShipments = (state) => state.shipments.list;

export const selectOutboundShipments = (state) => state.shipments.list.filter(a => a.direction === "outbound");
export const selectInboundShipments = (state) => state.shipments.list.filter(a => a.direction === "inbound");


export const selectDeliveryDate = ((state) => {
    const firstShipment = state.shipments.list[0];
    return getTimeslotDate(firstShipment?.requestedDeliveryDate);
});

export const selectWarehouses = ((state) => {
    const warehouses = state.shipments.list.map(s => s.address.id).filter(onlyUnique);
    return warehouses;
});

export const selectPalletQuantity = ((state) => {
    const qty = state.shipments.list.reduce((a, b) =>  a + b.palletsInShipment, 0);
    return qty;
});

export const selectRules = ((state) => {
    let rules = [];
    state.shipments.list.forEach(shp => {
        if (shp.rules){
            rules.push(...shp.rules);
        }
    });
    return rules;
});


export const selectLoading = (state) => state.shipments.loading;