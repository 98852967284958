import { toast } from "react-toastify";

const toastHandler = store => next => action => {
    if (action.type === "api/CallFailed") {
        console.log("Toastify", action);
        toast.error(action.payload.message);
    }
    else if(action.type === "shipments/requestFailed") {
        toast.error(action.payload.message);
        return next(action);
    } 
    else
    return next(action);
  };
  
  export default toastHandler;
  