import React, {useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Button } from "@mui/material";
import DateFilter from './../../../components/datefilter';
import styles from "./Step3.module.css";
import Times from "./times";
import { selectDeliveryDate, selectWarehouses, selectRules, selectPalletQuantity} from "../../../store/shipments";
import { loadTimeslots, selectTimeslots, timeslotSelected, selectedTimeslot  } from "../../../store/timeslots";
import { useSelector, useDispatch } from "react-redux";
import format from 'date-fns/format';

export default function Step3(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pallets = useSelector(selectPalletQuantity);
    const deliveryDate = useSelector(selectDeliveryDate);
    const warehouses = useSelector(selectWarehouses);
    const rules = useSelector(selectRules);
    const timeslots = useSelector(selectTimeslots);
    const timeslot = useSelector(selectedTimeslot);
    const [date, setDate] = useState(deliveryDate);

    /* eslint-disable */
    useEffect(() => {
        dispatch(loadTimeslots({requesteddate: date, pallets, warehouseId: warehouses[0], rules}));
    }, []);
    /* eslint-enable */

    const dateChanged = async (dateValue) => {
        const d = format(dateValue, "yyyy-MM-dd");
        setDate(d);
        dispatch(timeslotSelected({}));
        dispatch(loadTimeslots({requesteddate: d, pallets, warehouseId: warehouses[0], rules}));
    }

    const onTimeClick = (e, value) => {
        e.preventDefault();
        const t = {date, ...value};
        dispatch(timeslotSelected(t));
    };

    const nextDisabled = () => {
        if (timeslot) {
            const hasTimeslot = (timeslot.from)?true:false;
            const isVerified = !(props.verified===null);
            return !((hasTimeslot & isVerified)===1) ;
        }
        return true;


    };

  return (
    <div className={styles.container}>
      <div style={{ padding: "10px" }}>
          <DateFilter onChange={dateChanged} selectedDate={date}/>
        <Times onTimeClick={onTimeClick} times={timeslots} />
      </div>
      <div></div>
      <div className={styles.buttons}>
        <Button variant="outlined" onClick={props.previous} style={{marginRight: "10px"}}>{t('wizard.button2')}</Button>
        <Button variant="outlined" disabled={nextDisabled()} onClick={props.next}>
        {t('wizard.button3')}
        </Button>
      </div>
    </div>
  );
}
