import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CustomSnackbar from "../../components/CustomSnackbar";
import Step0 from "./Step0/Step0";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5/Step5";
import styles from "./bookingContainer.module.css";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getToken, selectCarrier } from "../../store/session";
import { selectShipments } from "../../store/shipments";
import { selectedTimeslot } from "../../store/timeslots";
import axios from "axios";

const api = window.API_URL;


export default function BookingContainer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [open, setOpen] = useState(false);
    const [inprogress, setInprogress] = useState(false);
    const [bookingSucceeded, setBookingSucceeded] = useState(false);
    const token = useSelector(getToken);
    const carrier = useSelector(selectCarrier);
    const shipments = useSelector(selectShipments);
    const timeslot = useSelector(selectedTimeslot);

    const steps = [t("wizard.step1"), t("wizard.step2"), t("wizard.step3"), t("wizard.step4"), t("wizard.step5"), t("wizard.step6")];


    const handleClose = (event, reason) => {
        setOpen(false);
    };

    const handleCancel = () => {
        const RESET_ACTION = {
            type: "RESET",
        };
        dispatch(RESET_ACTION);
        navigate("/");
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const doBooking = () => {
        handleNext();
        setInprogress(true);
        createBooking();
    };

    const handleFinish = () => {
        const RESET_ACTION = {
            type: "RESET",
        };
        dispatch(RESET_ACTION);

        navigate("/");
    };

    const createBooking = async () => {
        const data = { carrier, shipments, timeslot, language: i18n.language};
        await axios
            .post(api + "/booking", data, { withCredentials: true })
            .then((response) => {
                toast.info(t("wizard.success"));
                setBookingSucceeded(true);
            })
            .catch((error) => {
                if (error.response) {
                    console.log("response", error.response);
                    const {data} = error.response;
                    toast.error(data.message);
                }
                else {
                    toast.error(error.message || "Error");
                }
            });
            setInprogress(false);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Step0
                        previous={handleCancel}
                        next={handleNext}
                        verified={token}
                    />
                );
            case 1:
                return (
                    <Step1
                        previous={handleBack}
                        next={handleNext}
                        verified={token}
                    />
                );
            case 2:
                return (
                    <Step2
                        previous={handleBack}
                        next={handleNext}
                        verified={token}
                    />
                );
            case 3:
                return (
                    <Step3
                        previous={handleBack}
                        next={handleNext}
                        verified={token}
                    />
                );
            case 4:
                return (
                    <Step4
                        previous={handleBack}
                        next={doBooking}
                        verified={token}
                    />
                );
            case 5:
                return (
                    <Step5
                        next={handleFinish}
                        inProgress={inprogress}
                        succeeded = {bookingSucceeded}
                        verified={token}
                    />
                );
            default:
                return "Unknown step";
        }
    };

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.wizard}>
                    <div>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>
                                            {label}
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </div>
                    <div className={styles.step}>
                        {getStepContent(activeStep)}
                    </div>
                </div>
                <CustomSnackbar
                    open={open}
                    handleClose={handleClose}
                    message="Timeslot booked succesfully"
                />
                <ToastContainer />
            </div>
        </React.Fragment>
    );
}
