import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import styles from "./ShipmentInput.module.css";
import { Button, LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../store/shipments";

function ShipmentInput(props) {
    const { t } = useTranslation();
    const isLoading = useSelector(selectLoading);
    const [shipmentno, setShipmentno] = useState("");

    const onChangeHandler = (e) => {
        setShipmentno(e.target.value.toUpperCase());
    };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (shipmentno !=="")props.handleClick(shipmentno);
    setShipmentno("");
  };

  const disabled = () => {
    return shipmentno==="";
  };

  return (
    <div className={styles.container}>
      <form onSubmit={onSubmitHandler} className={styles.form}>
        <input
          className={styles.input}
          type="text"
          maxLength={30}
          pattern="[a-zA-Z0-9\s_-]{1,30}"
          value={shipmentno}
          placeholder={t('wizard.step2text1')}
          onChange={onChangeHandler}
        />
        {isLoading?
        <LinearProgress />
        :
        <Button disabled={disabled()} onClick={onSubmitHandler}>{t('wizard.step2text2')}</Button>
        }
        
      </form>
    </div>
  );
}

export default ShipmentInput;
