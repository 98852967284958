import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';
import { Grid, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import styles from "./Step1.module.css";
import { setCarrier, selectCarrier, verifyToken } from "../../../store/session";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

const siteKey  = window.SITE_KEY;
const DEBUG =window.DEBUG;

export default function Step1(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {register,handleSubmit,formState: { errors },  } = useForm();
    const carrier = useSelector(selectCarrier);

    useEffect(() => {
        if (DEBUG)dispatch(setCarrier({name:"Bekkerem Transport", email: "jan@bekkerem.nl", driver: "Jan Bekkerem", licenseplate: "X-PVF-6"}));
    }, []);

    const onSubmit = (data) => {
        dispatch(setCarrier(data));
        props.next();
  };

  const onChange = (value)=> {
    dispatch(verifyToken(value));
  }

  return (
      <React.Fragment>
          <div className={styles.container}>
              <div>
                  <form>
                      <Grid container spacing={2}>
                          <Grid item xs={12}></Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={6}>
                              <TextField
                                  fullWidth
                                  id="name"
                                  label={t('wizard.step1text1')}
                                  defaultValue={carrier.name}
                                  variant="outlined"
                                  error={errors.carrier}
                                  helperText={
                                      errors.carrier &&
                                      "Carrier name is required"
                                  }
                                  {...register("name", {
                                      required: !DEBUG,
                                      maxLength: 100,
                                  })}
                              />
                          </Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={6}>
                              <TextField
                                  fullWidth
                                  id="email"
                                  type="email"
                                  label={t('wizard.step1text2')}
                                  defaultValue={carrier.email}
                                  variant="outlined"
                                  error={errors.email}
                                  helperText={
                                      errors.email &&
                                      "Not a valid email address"
                                  }
                                  {...register("email", {
                                      required: !DEBUG,
                                      pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "invalid email address",
                                      },
                                  })}
                              />
                          </Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={6}>
                              <TextField
                                  id="driver"
                                  fullWidth
                                  label={t('wizard.step1text3')}
                                  defaultValue={carrier.driver}
                                  variant="outlined"
                                  error={errors.driver}
                                  helperText={
                                      errors.driver && "Driver name not valid"
                                  }
                                  {...register("driver", {
                                      required: false,
                                      maxLength: 100,
                                  })}
                              />
                          </Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={6}>
                              <TextField
                                  id="licenseplate"
                                  //style={{ width: "100%" }}
                                  label={t('wizard.step1text4')}
                                  fullWidth
                                  defaultValue={carrier.licenseplate}
                                  variant="outlined"
                                  error={errors.licenseplate}
                                  helperText={
                                      errors.licenseplate &&
                                      "licenseplate not valid"
                                  }
                                  {...register("licenseplate", {
                                      required: false,
                                      maxLength: 100,
                                  })}
                              />
                          </Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={6}>
                              <ReCAPTCHA
                                sitekey={siteKey}
                                onChange={onChange}
                                size="normal"
                                badge="bottomright"
                            />
                          </Grid>
                          <Grid item xs={3}></Grid>
                      </Grid>
                  </form>
              </div>
              <div></div>
              <div className={styles.buttons}>
                  <Button variant="outlined" onClick={props.previous} style={{marginRight: "10px"}}
                  >{t('wizard.button2')}</Button> 
                  <Button variant="outlined" onClick={handleSubmit(onSubmit)} disabled={!props.verified} >{t('wizard.button3')}</Button>
              </div>
          </div>
      </React.Fragment>
  );
}
