import React from "react";
import { useTranslation } from 'react-i18next';
import { Button } from "@mui/material";
import styles from "./Step4.module.css";
import { format } from "date-fns";
import { selectedTimeslot } from "../../../store/timeslots";
import { selectCarrier } from "../../../store/session";
import { useSelector } from "react-redux";


export default function Step4(props) {
    const { t } = useTranslation();
    const timeslot = useSelector(selectedTimeslot);
    const carrier = useSelector(selectCarrier);

    return (
    <div className={styles.container}>
      <div className={styles.summary}>
         {t('wizard.step4text1')}
          <p>{t('wizard.step4text2')}:{" "}{carrier.name}</p>  
        <p>{t('wizard.step4text3')}:{" "}{(timeslot)?format(new Date(timeslot.date),"dd-MM-yyyy"):""}  {t('wizard.step4text4', {from: timeslot.from, till: timeslot.till})}</p>
        <p>{t('wizard.step4text5')}</p>
      </div>
      <div></div>
      <div className={styles.buttons}>
        <Button onClick={props.previous}>{t('wizard.button2')}</Button>
        <Button onClick={props.next}>{t('wizard.button4')}</Button>
      </div>
    </div>
  );
}
