import {
    format,
    getHours,
    isBefore,
    addBusinessDays,
    isWeekend,
    nextMonday,
    add
} from "date-fns";

// voorstel datum bij selecteren tijdsslot
export function getTimeslotDate(date) {
    const now = new Date();
    let ret = new Date();
    const timestamp = Date.parse(date);
    if (isNaN(timestamp) === false) {
        ret = isBefore(timestamp, add(now, {days: 1})) ? add(now, {days:1}) : timestamp;
    }
    if (getHours(ret) >= 16) {
        ret = addBusinessDays(ret, 1);
    }
    if (isWeekend(ret)) {
        ret = nextMonday(ret);
    }

    return format(ret, "yyy-MM-dd");
}
