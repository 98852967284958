import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "session",
  initialState: { token: '', carrier: {}, direction: "outbound"},
  reducers: {
    sessionVerified: (session, action) => {
        session.token = action.payload;
    },
    setCarrier: (session, action) => {
        session.carrier = action.payload;
    },
    setDirection: (session, action) => {
        session.direction = action.payload;
    }
  }
});

export const { sessionVerified, setCarrier, setDirection } = slice.actions;

const url = "/session";

export const verifyToken = (token) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        url: url + `/create`,
        method: "post",
        data: {token},
        onSuccess: sessionVerified.type
      })
    );
  };

export default slice.reducer;

// Selector
export const getToken = (state) => state.session.token;

export const selectCarrier = (state) => state.session.carrier;

export const selectDirection = (state) => state.session.direction;