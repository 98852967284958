import React, {useState} from "react";
import { Route, Routes } from "react-router-dom";
import HomeContainer from "./scenes/home/homeContainer";
import BookingContainer from "./scenes/booking/bookingContainer";
import store from "./store";
import { Provider } from "react-redux";

import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import DateFilter from "./components/datefilter";
import format from 'date-fns/format';




function App() {
    const [date, setDate] = useState();

const dateChanged = async (dateValue) => {
    const d = format(dateValue, "yyyy-MM-dd");
    setDate(d);
    console.log("Selected date:", d);
}
  return (
      <Provider store={store}>
        <React.Fragment>
            <div className="App">
                <Routes>
                    <Route path="/" element={<HomeContainer />} />
                    <Route path="/timeslot" element={<BookingContainer />} />
                    <Route path="/test" element={<DateFilter onChange={dateChanged} selectedDate={date}/>} />
                </Routes>
            </div>
        </React.Fragment>
    </Provider>
  );
}

export default App;
