import React from "react";
import { Button, Radio, RadioGroup,FormControlLabel, FormLabel } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { setDirection, selectDirection } from "../../../store/session";
import { clearShipments } from "../../../store/shipments";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Step0.module.css";


export default function Step0(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const value = useSelector(selectDirection);

    const handleChange = (event) => {
        dispatch(setDirection(event.target.value));
        dispatch(clearShipments());
      };
    const onNext = () => {
        props.next();
  };

    return (
        <div className={styles.container}>
            <div className={styles.summary}>
            <FormLabel id="formlabel">{t("wizard.step0description")}</FormLabel>
                <br />
                <RadioGroup value={value} onChange={handleChange} name="radio-buttons-group">
                    <FormControlLabel
                        value="inbound"
                        control={<Radio />}
                        label={t("wizard.step0option1")}
                    />
                    <FormControlLabel
                        value="outbound"
                        control={<Radio />}
                        label={t("wizard.step0option2")}/>
                    <FormControlLabel
                        value="inbound_outbound"
                        control={<Radio />}
                        label={t("wizard.step0option3")} />
                </RadioGroup>
            </div>
            <div></div>
            <div className={styles.buttons}>
                <Button onClick={props.previous}>{t("wizard.button1")}</Button>
                <Button onClick={onNext}>{t("wizard.button3")}</Button>
            </div>
        </div>
    );
}
