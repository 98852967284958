import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "timeslots",
  initialState: {
    list: [],
    loading: false,
    selectedItem: {}
  },
  reducers: {
    timeslotsRequested: (timeslots, action) => {
        timeslots.loading = true;
    },
    timeslotsReceived: (timeslots, action) => {
        if (action.payload.length > 0){
            const times = action.payload;
            timeslots.list=[...times];
        }
        else{
            timeslots.list = [];
        }
        timeslots.loading = false;
    },
    timeslotsRequestFailed: (timeslots, action) => {
        timeslots.loading = false;
        timeslots.list = [];
    },
    timeslotSelected: (timeslots, action) => {
        const {payload} = action;
        timeslots.selectedItem = payload;
        }
    },
});

export const {
    timeslotsReceived,
    timeslotsRequested,
    timeslotsRequestFailed,
    timeslotSelected
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "/timeslots";

export const loadTimeslots = (criteria) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/search`,
      method: "post",
      data: criteria,
      onStart: timeslotsRequested.type,
      onSuccess: timeslotsReceived.type,
      onError: timeslotsRequestFailed.type,
    })
  );
};

// Selectors
export const selectTimeslots = (state) => state.timeslots.list;

export const selectedTimeslot = (state) => state.timeslots.selectedItem;


