import React from 'react';
import styles from "./ShipmentItem.module.css";
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function ShipmentItem(props) {

    return (
        <div className={styles.container}>
            <div className={styles.item}>
                <span className={styles.text}>
                    {props.referenceNumber}{" "}
                    {props.palletsInShipment ? " ( " + Math.ceil(props.palletsInShipment) + " pallets )" : ""}
                </span>
            </div>
            <div>
                <Button onClick={props.clickToDelete}>
                    <DeleteIcon />
                </Button>
            </div>
        </div>
    );
}

export default ShipmentItem;