import React from "react";
import styles from "./times.module.css";
import { selectedTimeslot } from "../../../store/timeslots";
import { useSelector } from "react-redux";
var classNames = require("classnames/bind");

let cx = classNames.bind(styles);

function Times(props) {
    const timeslot = useSelector(selectedTimeslot);

    const selected = (from) => {
        if (!timeslot){return false;}
        return (from === timeslot.from);
    }

  const getTimes = () => {
      if (props.times && props.times.length > 0) {
          return (
              <div className={styles.container}>
                  {props.times.map((t) => (
                      <div key={t.from} style={{width: "150px", margin: "0px 5px"}}>
                      <button
                          className={cx({
                              button: true,
                              selected: selected(t.from),
                          })}
                          onClick={(e) => props.onTimeClick(e, t)}
                      >
                          {t.from}-{t.till}
                      </button>
                      </div>
                  ))}
              </div>
          );
      }
      else
      {
          return <div style={{ display: "flex", marginTop: "10px", justifyContent: "center", width: "100%" }}><div>No time slots available</div></div>;
      }
  };

  return <div className={styles.container}>{getTimes()}</div>;
}

export default Times;
