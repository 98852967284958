import React from "react";
import ShipmentItem from "./ShipmentItem";
import { shipmentRemoved } from "../../../store/shipments";
import { useDispatch } from "react-redux";

function ShipmentList(props) {
    const dispatch = useDispatch();


    return (
        <div>
            <div><b>{props.shipments.length !== 0 ? props.caption: ""}</b></div>
            <div>
                {props.shipments
                    ? 
                    props.shipments
                          .map((shipment) => (
                              <ShipmentItem
                                  key={shipment.id}
                                  {...shipment}
                                  clickToDelete={() =>
                                      dispatch(shipmentRemoved(shipment))
                                  }
                              />
                          ))
                    : ""}
            </div>
        </div>
    );
}

export default ShipmentList;
