import { combineReducers } from "redux";
import sessionReducer from "./store/session";
import shipmentReducer from "./store/shipments";
import timeslotReducer from "./store/timeslots";

const allReducers = combineReducers({
    session: sessionReducer,
    shipments: shipmentReducer,
    timeslots: timeslotReducer
  });
  

const rootReducer = (state, action)=> {
    if (action.type === 'RESET') {
        state = undefined;
      }
    return allReducers(state, action);
}

export default rootReducer;
