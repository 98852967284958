import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    home: {
                        button: "Register a time slot",
                    },
                    wizard: {
                        step1: "Action",
                        step2: "Carrier",
                        step3: "Shipments",
                        step4: "Timeslot",
                        step5: "Summary",
                        step6: "Confirmation",
                        success: "Booking created",
                        button1: "Cancel",
                        button2: "Previous",
                        button3: "Next",
                        button4: "Finish",
                        button5: "Done",
                        step0description:
                            "Choose what you are booking a timeslot for",
                        step0option1: "I am going to deliver goods",
                        step0option2: "I am going to pick up goods",
                        step0option3:
                            "I am going to do both (deliver and pickup)",
                        step1text1: "Carrier name",
                        step1text2: "Email",
                        step1text3: "Driver name",
                        step1text4: "License plate",
                        step2text1: "Enter shipment number here:",
                        step2text2: "Add",
                        step2text3: "Delivery",
                        step2text4: "Pick up",
                        step3text1: "Date",
                        step4text1:
                            "We will reserve the following timeslot for you:",
                        step4text2: "Carrier",
                        step4text3: "Timeslot",
                        step4text4: "Between  {{from}} and {{till}}",
                        step4text5:
                            "You will receive a confirmation of your time slot by email",
                        step5text1: "Thank you for registering the time slot",
                        step5text2: "An email will be send to",
                    },
                },
            },
            nl: {
                translation: {
                    home: {
                        button: "Tijdslot boeken",
                    },
                    wizard: {
                        step1: "Actie",
                        step2: "Vervoerder",
                        step3: "Zendingen",
                        step4: "Tijdsslot",
                        step5: "Samenvatting",
                        step6: "Bevestiging",
                        success: "Tijddslot geregistreerd",
                        button1: "Annuleren",
                        button2: "Vorige",
                        button3: "Volgende",
                        button4: "Voltooien",
                        button5: "Sluiten",
                        step0description:
                            "Waarvoor wilt u een tijdslot registreren?",
                        step0option1: "Voor het afleveren van goederen",
                        step0option2: "Voor het ophalen van goederen",
                        step0option3: "Voor zowel afhalen als leveren",
                        step1text1: "Naam vervoerder",
                        step1text2: "Email",
                        step1text3: "Naam chauffeur",
                        step1text4: "Kenteken",
                        step2text1: "Voer hier uw referentie in",
                        step2text2: "Toevoegen",
                        step2text3: "Levering",
                        step2text4: "Afhaling",
                        step3text1: "Datum",
                        step4text1:
                            "Wij zullen het volgende tijdsslot voor u reserveren",
                        step4text2: "Vervoerder",
                        step4text3: "Tijdslot",
                        step4text4: "Tussen  {{from}} en {{till}}",
                        step4text5:
                            "Er zal een email verzonden worden naar het opgegeven e-mail adres ter bevestiging",
                        step5text1:
                            "Dank u voor het reserveren van het tijdslot",
                        step5text2: "Een email wordt verzonden naar",
                    },
                },
            },
            de: {
                translation: {
                    home: {
                        button: "buchen Sie ein Zeitfenster",
                    },
                    wizard: {
                        step1: "Action",
                        step2: "Spediteur",
                        step3: "Sendungen",
                        step4: "Zeitfenster",
                        step5: "Zusammenfassung",
                        step6: "Bestätigung",
                        success: "Zeitfenster registriert",
                        button1: "Stornieren",
                        button2: "Vorherige",
                        button3: "Nächste",
                        button4: "Fertig",
                        button5: "Schliesssen",
                        step0description:
                            "Wöfur möchten Sie ein Zeitfenster registrieren",
                        step0option1: "Für Lieferung von Waren",
                        step0option2: "Zum abholen von Waren",
                        step0option3: "Sowohl für Abholung als Lieferung",
                        step1text1: "Name des Spediteurs",
                        step1text2: "Email",
                        step1text3: "Name des Fahrers",
                        step1text4: "Kennzeichen",
                        step2text1: "Geben Sie Ihre Referenz ein",
                        step2text2: "Hinzufügen",
                        step2text3: "Lieferungen",
                        step2text4: "Abholungen",
                        step3text1: "Datum",
                        step4text1:
                            "Wir Reservieren das nächste Zeitfenster für Sie",
                        step4text2: "Spediteur",
                        step4text3: "Zeitfenster",
                        step4text4: "Zwischen  {{from}} und {{till}}",
                        step4text5:
                            "Zur Bestätigung wird eine E-Mail an die angegebene E-Mail-Adresse gesende",
                        step5text1:
                            "Vielen Dank für die Buchung des Zeitfensters",
                        step5text2: "Ein Email wird gesendet an",
                    },
                },
            },
            pl: {
                translation: {
                    home: {
                        button: "Zarejestruj przedział czasowy",
                    },
                    wizard: {
                        step1: "Akcja",
                        step2: "Przewoźnik",
                        step3: "Przesyłki",
                        step4: "Przedział czasu",
                        step5: "streszczenie",
                        step6: "Potwierdzenie",
                        success: "Rezerwacja utworzona",
                        button1: "Anulować",
                        button2: "Poprzedni",
                        button3: "Następny",
                        button4: "Skończyć",
                        button5: "Gotowe",
                        step0description:
                            "Wybierz, na co rezerwujesz przedział czasowy",
                        step0option1: "mam zamiar dostarczyć towar",
                        step0option2: "mam zamiar odebrać towar",
                        step0option3:
                            "Zamierzam zrobić jedno i drugie (dostarczenie i odbiór)",
                        step1text1: "Nazwa przewoźnika",
                        step1text2: "E-mail",
                        step1text3: "Imię kierowcy",
                        step1text4: "Tablica rejestracyjna",
                        step2text1: "Wpisz tutaj numer przesyłki:",
                        step2text2: "Dodać",
                        step2text3: "Dostawa",
                        step2text4: "Odbior",
                        step3text1: "Data",
                        step4text1:
                            "Zarezerwujemy dla Ciebie następujący przedział czasowy:",
                        step4text2: "Przewoźnik",
                        step4text3: "Przedział czasu",
                        step4text4: "Między {{from}} a {{till}}", 
                        step4text5:
                            "Otrzymasz potwierdzenie swojego przedziału czasowego e-mailem",
                        step5text1:
                            "Dziękujemy za zarejestrowanie przedziału czasowego",
                        step5text2: "E-mail zostanie wysłany do",
                    },
                },
            },
            es: {
                translation: {
                    home: {
                        button: "Registre una franja horaria",
                    },
                    wizard: {
                        step1: "Acción",
                        step2: "Transportista",
                        step3: "Envíos",
                        step4: "Franja horaria",
                        step5: "Resumen",
                        step6: "Confirmación",
                        success: "Reserva creada",
                        button1: "Cancelar",
                        button2: "Anterior",
                        button3: "Siguiente",
                        button4: "Finalizar",
                        button5: "Cerrarr",
                        step0description:
                            "Seleccione qué esta registrando dentro de esta franja horaria",
                        step0option1: "Voy a entregar mercancía ",
                        step0option2: "Voy a recoger mercancía",
                        step0option3: "Voy a hacer ambos (Entrega y recogida)",
                        step1text1: "Nombre del transportista",
                        step1text2: "Correo electrónico",
                        step1text3: "Nombre del chofer",
                        step1text4: "Número de placa",
                        step2text1: "Ingrese aquí su número de envío",
                        step2text2: "Agregar",
                        step2text3: "Entrega ",
                        step2text4: "Recogida",
                        step3text1: "Fecha",
                        step4text1:
                            "Reservaremos el siguiente horario para usted:",
                        step4text2: "Transportista",
                        step4text3: "Franja horaria",
                        step4text4: "Entre {{from}} y {{till}}",
                        step4text5:
                            "Recibirá un correo electrónico a la dirección especificada con su confirmación ",
                        step5text1: "Gracias por reservar su franja horaria",
                        step5text2: "Un correo electrónico será enviado a ",
                    },
                },
            },
            fr: {
                translation: {
                    home: {
                        button: "Réserver un créneau horaire",
                    },
                    wizard: {
                        step1: "Action",
                        step2: "Transporteur",
                        step3: "Expédition",
                        step4: "Créneau horaire",
                        step5: "Résumé",
                        step6: "Confirmation",
                        success: "Réservation créée",
                        button1: "Annuler",
                        button2: "Précédent",
                        button3: "Suivant",
                        button4: "Conclure",
                        button5: "Terminé",
                        step0description: "Pourquoi réserver vous un créneau",
                        step0option1: "Livraison de marchandise",
                        step0option2: "Chargement de marchandise",
                        step0option3: "Livraison + Chargement",
                        step1text1: "Nom du transporteur",
                        step1text2: "Email",
                        step1text3: "Nom du chauffeur",
                        step1text4: "Plaque d'immatriculation",
                        step2text1: "Référence de chargement:",
                        step2text2: "Ajouter",
                        step2text3: "Livraison ",
                        step2text4: "Chargement ",
                        step3text1: "Date",
                        step4text1: "Nous vous réserverons le temps suivant :",
                        step4text2: "Transporteur",
                        step4text3: "Créneau horaire ",
                        step4text4: "Entre {{from}} et {{till}}",
                        step4text5:
                            "Nous vous réserverons le prochain créneau:",
                        step5text1:
                            "Merci d'avoir réserver votre créneau horaire",
                        step5text2: "Un email sera envoyé à ",
                    },
                },
            },
        },
    });

export default i18n;
