import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "@mui/material";
import styles from "./Step2.module.css";
import ShipmentInput from "./ShipmentInput";
import ShipmentList from "./ShipmentList";
import { AddShipment, selectShipments,selectOutboundShipments, selectInboundShipments } from "../../../store/shipments";
import { selectDirection } from "../../../store/session";
import { useDispatch, useSelector } from "react-redux";
import {toast } from "react-toastify";

export default function Step2(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const shipments = useSelector(selectShipments);
    const outbound = useSelector(selectOutboundShipments);
    const inbound = useSelector(selectInboundShipments);
    const direction = useSelector(selectDirection);

    const nextDisabled =() => {
        if (!shipments)return true;
        return !(((shipments.length > 0) & !(props.verified===null))===1) ;
    }

    const addShipment = (shipmentno) => {
        const exists1 = inbound.filter(a => a.referenceNumber === shipmentno);
        const exists2 = inbound.filter(a => a.documentNumber === shipmentno);
        const exists3 = outbound.filter(a => a.referenceNumber === shipmentno);
        const exists4 = outbound.filter(a => a.documentNumber === shipmentno);
        const tot = [].concat(exists1, exists2, exists3, exists4);
        if (tot.length > 0){
            toast.info(`Shipment ${shipmentno} already in list`);
        }
        else
        {
            dispatch(AddShipment({direction, shipmentno}))
        }
    }

    const next = () => {
        if (direction==="inbound_outbound" && outbound.length===0){
            toast.warn("Please add an shipment to pickup.");
            return;
        }
        if (direction==="inbound_outbound" && inbound.length===0){
            toast.warn("Please add an shipment to deliver.");
            return;
        }
        props.next();
    }

    return (
    <div className={styles.container}>
        <div>
        <ShipmentInput handleClick={addShipment}/>
        <div style={{display: "flex"}}>
            <div style={{width: "50%"}}><ShipmentList shipments={inbound} caption={t('wizard.step2text3')}/></div>
            <div style={{width: "50%"}}><ShipmentList shipments={outbound} caption={t('wizard.step2text4')}/></div>
        </div>

        </div>
        <div></div>
        <div className={styles.buttons}>
        <Button variant="outlined" onClick={props.previous} style={{marginRight: "10px"}}>{t('wizard.button2')}</Button>
        <Button variant="outlined" disabled={nextDisabled()} onClick={next}>{t('wizard.button3')}</Button>
        </div>
    </div>
    );
}
