import {Snackbar, Alert} from "@mui/material";

const CustomSnackbar = props => {
    return ( 
        <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        open={props.open}
        autoHideDuration={3000}
        onClose={props.handleClose}
      >
      <Alert onClose={props.handleClose} severity="success" sx={{ width: '100%' }}>
    {props.message}
  </Alert>
</Snackbar>
    );
  };
  
  export default CustomSnackbar;